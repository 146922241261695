<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-08-05 16:28:48
-->
<template>
	<div class="notive">
		<div class="notive-content">
			<div class="notive-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column width="200px" prop="title" label="公告类型">
					</el-table-column>
					<el-table-column prop="content" label="内容">
						<template slot-scope="scope">
							<el-popover placement="top" title="" content="" width="800" trigger="hover">
								<div class="action-popover-content" v-html="scope.row.content"></div>
								<div class="action-popover-btn" slot="reference" v-html="scope.row.content"></div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('edit', scope)">编辑</span>
								<!-- <el-divider direction="vertical"></el-divider>
								<span @click="actionFn('delete', scope)">删除</span> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- <div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getNoticeList
	} from "@/api/system";
	export default {
		components: {},
		data() {
			return {
				tableData: [],
				searchForm: {},
				totalCount: 0,
				pageIndex: 1,
				pageSize: 999,
			};
		},
		watch: {},
		mounted() {
			this.noticeList();
		},
		methods: {
			noticeList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				};
				getNoticeList(params).then(res => {
					if (res.code == 0) {
						this.tableData = res.data;
						this.totalCount = res.page.totalCount * 1
					}
				});
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.noticeList();
			},
			actionFn(type, scope) {
				switch (type) {
					case "edit":
						this.$router.push({
							path: `/noticeManage/detail?noticeId=${scope.row.id}&&noticeType=${scope.row.type}`
						});
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #dcdfe6;
	}

	/deep/img {
		width: 200px;
		height: auto;
	}
	
	.action-popover-btn {
		max-height: 88px;
		overflow: hidden;
		cursor: pointer;
	}
	
	.action-popover-content {
		max-height: 500px;
		overflow-y: auto;
	}

	.notive {
		.notive-content {
			padding: 0 20px 20px 20px;

			.notive-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #edf1f7;
							border-left: 0;
						}
					}
				}
			}

			.notive-table {
				margin-top: 20px;
			}
		}
	}
</style>
